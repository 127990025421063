/* Reset default form styles */
.hs-form-private {
  margin: 0;
  padding: 0;
}

.hs-input {
  width: 100%;
}

/* Form layout */
.hs-form-private {
  display: flex;
  flex-direction: column;
}

/* Form columns */
.form-columns-2,
.form-columns-1,
.fieldset {
  width: 100%;
  max-width: 100% !important;
  margin-bottom: 15px;
}

/* Form fields */
.hs-form-field {
  width: 100%;
  /* Adjust as needed for spacing between fields */
  margin-bottom: 15px;
}

.hs-lastname {
  width: 100%;
}

.hs-fieldtype-text label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.hs-input {
  width: 100% !important;
  padding: 8px;
  border: 1px solid #e5e7eb;
  /* Equivalent to border-secondary-ui-200 */
  border-radius: 0.375rem;
  /* Equivalent to rounded-md */
  background-color: #fff;
  font-family: inherit;
}

/* Error messages */
.hs-error-msgs {
  margin-top: 5px;
  list-style: none;
  padding-left: 0;
}

.hs-error-msg {
  color: #f00;
  font-size: 12px;
}

/* Submit button */
.hs-submit {
  text-align: center;
}

.hs-button {
  width: 100%;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  /* Equivalent to rounded-full */
  padding: 3px 18px;
  /* Equivalent to px-6 py-3 */
  font-size: 14px;
  /* Equivalent to text-sm */
  background-color: black !important;
  /* Equivalent to bg-primary-ui-black */
  color: white;
  /* Equivalent to text-primary-ui-white */
  border: 1px solid white;
  /* Equivalent to border border-primary-ui */
  cursor: pointer;
  /* Assuming this is for a button-like element */

  /* Pseudo-classes for hover state */
  background-color: rgba(0, 0, 0, 0.8);
  /* Equivalent to hover:bg-primary-ui-black-80 */
  border-color: rgba(0, 0, 0, 0.8);
  /* Equivalent to hover:border-primary-ui-black-80 */
  /* You can add additional hover styles as needed */
}

.hs-button:hover {
  background-color: #0056b3;
}

/* hs-input invalid error */

.error {
  border: 1px solid #f00;
}

.hs-error-msg {
  color: #f00 !important;
}

/* Success Message */

.submitted-message {
  margin: auto;
  margin-top: 5rem;
  text-align: center;
  margin-bottom: auto;
}
