@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Break out of fixed width container */
  /* requires the parent to be exactly centered in the browser. */
  .full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .constrained-width {
    width: auto;
    left: unset;
    right: unset;
    margin-left: unset;
    margin-right: unset;
  }
}

@layer base {
  /* Bubble Shapes */
  .bubble {
    clip-path: url(#bubble);
  }

  .bubble-wide {
    clip-path: url(#bubble-wide);
  }

  .bubble-tall {
    clip-path: url(#bubble-tall);
  }

  .bubble-tall-horizontal {
    clip-path: url(#bubble-tall-horizontal);
  }

  .razor {
    clip-path: url(#razor);
  }

  /* Bolt Shapes */
  .bolt {
    clip-path: url(#bolt);
  }

  .bolt-wide {
    clip-path: url(#bolt-wide);
  }

  .bolt-tall {
    clip-path: url(#bolt-tall);
  }

  /* Cube Shapes */
  .cube {
    clip-path: url(#cube);
  }

  .cube-wide {
    clip-path: url(#cube-wide);
  }

  .cube-tall {
    clip-path: url(#cube-tall);
  }

  .cube-large {
    clip-path: url(#cube-large);
  }

  .cube-masters {
    clip-path: url(#cube-masters);
  }

  /* Tab Shapes */
  .tab {
    clip-path: url(#tab);
  }

  .tab-wide {
    clip-path: url(#tab-wide);
  }

  .tab-tall {
    clip-path: url(#tab-tall);
  }

  .tab-large {
    clip-path: url(#tab-large);
  }

  .clipped-background {
    clip-path: polygon(
      0 0,
      calc(100% - 100px) 0,
      100% 100px,
      100% 100%,
      100px 100%,
      0 calc(100% - 100px)
    );
  }

  .lineColor::after,
  .lineColor::before {
    background-color: var(--data-attribute-line-color) !important;
    z-index: -1;
  }

  :root {
    /* Brand Colours */
    --color-brand-100: rgba(255, 239, 230, 1);
    --color-brand-600: rgba(255, 97, 0, 1);
    --color-brand-800: rgba(206, 79, 0, 1);
    --color-brand-900: rgba(186, 71, 0, 1);

    /* Accent (e.g. background) Colours */
    --color-accent-2-100: rgba(247, 248, 211, 1);

    --color-accent-4-50: rgba(245, 243, 255, 1);
    --color-accent-4-100: rgba(232, 237, 251, 1);
    --color-accent-4-200: rgba(210, 220, 247, 1);
    --color-accent-4-600: rgba(62, 115, 215, 1);
    --color-accent-4-700: rgba(0, 75, 213, 1);

    --color-accent-6-300: rgba(235, 202, 245, 1);
    --color-accent-6-600: rgba(228, 111, 222, 1);

    /* Primary Colours */
    --color-primary-ui-00: rgba(255, 255, 255, 1);
    --color-primary-ui-00-20: rgba(255, 255, 255, 0.2);
    --color-primary-ui-00-50: rgba(255, 255, 255, 0.5);
    --color-primary-ui-00-80: rgba(255, 255, 255, 0.8);
    --color-primary-ui-900: rgba(27, 24, 25, 1);
    --color-primary-ui-900-20: rgba(27, 24, 25, 0.2);
    --color-primary-ui-900-50: rgba(27, 24, 25, 0.5);
    --color-primary-ui-900-80: rgba(27, 24, 25, 0.8);

    /* Secondary Colours */
    --color-secondary-ui-50: rgba(242, 242, 242, 1);
    --color-secondary-ui-100: rgba(230, 230, 230, 1);
    --color-secondary-ui-200: rgba(204, 204, 204, 1);
    --color-secondary-ui-300: rgba(153, 153, 153, 1);
    --color-secondary-ui-400: rgba(156, 163, 175, 1);
    --color-secondary-ui-500: rgba(102, 102, 102, 1);
    --color-secondary-ui-600: rgba(75, 85, 99, 1);
    --color-secondary-ui-700: rgba(51, 51, 51, 1);
    --color-secondary-ui-900: rgba(17, 24, 39, 1);
    --data-attribute-line-color: var(--color-brand-600);

    --bg-color: #fff;
  }

  .dark {
    /* Dark theme variable changes go here */
  }
}

div[data-lastpass-icon-root] {
  display: none;
}

body {
  overflow-x: hidden;
}

p,
ul,
ol {
  @apply mb-4;
}

ol p {
  @apply inline;
}

ul {
  @apply list-disc ml-5;
}

li ul {
  @apply list-disc ml-8;
}

ul p {
  @apply mb-0;
}

ol {
  @apply list-decimal pl-10;
}

ol p {
  @apply mb-0;
}

.grid-item svg {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: blueviolet;
}

.grid-item:hover svg {
  opacity: 1;
  cursor: pointer;
}

.infinity-slider-block .swiper-wrapper {
  transition-timing-function: linear;
}

.infinity-slider-block .swiper {
  overflow: visible;
}

.browse-corner-cut::before {
  content: '\A';
  border-style: solid;
  border-width: 0 255px 220px 0;
  border-color: transparent transparent white transparent;
  position: absolute;
  left: 0;
}

* {
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
}

.markdown a {
  @apply text-accent-4-700 underline underline-offset-4 dark:text-inherit;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
*::-webkit-scrollbar {
  display: none;
}

section {
  scroll-margin-top: 10rem;
  scroll-behavior: smooth;
}
