@font-face {
  font-family: 'Inter';
  src: url('/digitalpassport/fonts/Inter-Thin.ttf') format('truetype'),
    url('/digitalpassport/fonts/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: url('/digitalpassport/fonts/Inter-ExtraLight.ttf') format('truetype'),
    url('/digitalpassport/fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: url('/digitalpassport/fonts/Inter-Light.ttf') format('truetype'),
    url('/digitalpassport/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url('/digitalpassport/fonts/Inter-Regular.ttf') format('truetype'),
    url('/digitalpassport/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('/digitalpassport/fonts/Inter-Medium.ttf') format('truetype'),
    url('/digitalpassport/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('/digitalpassport/fonts/Inter-SemiBold.ttf') format('truetype'),
    url('/digitalpassport/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('/digitalpassport/fonts/Inter-Bold.ttf') format('truetype'),
    url('/digitalpassport/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url('/digitalpassport/fonts/Inter-ExtraBold.ttf') format('truetype'),
    url('/digitalpassport/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: url('/digitalpassport/fonts/Inter-Black.ttf') format('truetype'),
    url('/digitalpassport/fonts/Inter-Black.ttf') format('truetype');
  font-weight: 900;
}
